// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* colors */
  --main-bg-color: #ffffff;
  --line-gray-color: #dadce0;
  --light-grey: #eeeeee;

  --blue-info: #1967d2;
  --light-blue: #e8f0fe;

}

header {
  height: 50px;
  background-color: #333;
  border-bottom: 1px solid #333;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arial";
  color: rgba(0, 0, 0, 0.7);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  height: 40px;
  background-color: var(--main-bg-color);
  border-top: 1px solid var(--line-gray-color);
  padding: 10px 10px 10px 10px;
  text-align: center;
  font-size: 0.9em;
  margin: 0 auto;
}

/*#main-wrapper {*/
/*  min-height: 85vh;*/
/*  display: grid;*/
/*  !*background: url('http://testbed.nicon.nl/img/_FBs3b.svg') repeat;*!*/
/*  background-size: 10px 10px;*/
/*  !* grid-template-columns: 1fr 70% 1fr; *!*/
/*}*/

#left-sidebar {
  grid-column: 1;
  border-right: 1px solid var(--line-gray-color);
}

#right-sidebar {
  grid-column: 3;
  border-left: 1px solid var(--line-gray-color);
}`, "",{"version":3,"sources":["webpack://./src/components/bpmn/styles/main.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAwB;EACxB,0BAA0B;EAC1B,qBAAqB;;EAErB,oBAAoB;EACpB,qBAAqB;;AAEvB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,UAAU;EACV,oBAAoB;EACpB,yBAAyB;EACzB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,sCAAsC;EACtC,4CAA4C;EAC5C,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA,kBAAkB;AAClB,sBAAsB;AACtB,mBAAmB;AACnB,0EAA0E;AAC1E,gCAAgC;AAChC,8CAA8C;AAC9C,IAAI;;AAEJ;EACE,cAAc;EACd,8CAA8C;AAChD;;AAEA;EACE,cAAc;EACd,6CAA6C;AAC/C","sourcesContent":[":root {\n  /* colors */\n  --main-bg-color: #ffffff;\n  --line-gray-color: #dadce0;\n  --light-grey: #eeeeee;\n\n  --blue-info: #1967d2;\n  --light-blue: #e8f0fe;\n\n}\n\nheader {\n  height: 50px;\n  background-color: #333;\n  border-bottom: 1px solid #333;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: \"Arial\";\n  color: rgba(0, 0, 0, 0.7);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nfooter {\n  height: 40px;\n  background-color: var(--main-bg-color);\n  border-top: 1px solid var(--line-gray-color);\n  padding: 10px 10px 10px 10px;\n  text-align: center;\n  font-size: 0.9em;\n  margin: 0 auto;\n}\n\n/*#main-wrapper {*/\n/*  min-height: 85vh;*/\n/*  display: grid;*/\n/*  !*background: url('http://testbed.nicon.nl/img/_FBs3b.svg') repeat;*!*/\n/*  background-size: 10px 10px;*/\n/*  !* grid-template-columns: 1fr 70% 1fr; *!*/\n/*}*/\n\n#left-sidebar {\n  grid-column: 1;\n  border-right: 1px solid var(--line-gray-color);\n}\n\n#right-sidebar {\n  grid-column: 3;\n  border-left: 1px solid var(--line-gray-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
