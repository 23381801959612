import "./styles/App.css";
import React, { useState, useEffect } from "react";
import Forgot from "./form/forgot";
import Login from "./form/login";
import Register from "./form/register";
import Main from "./components/bpmn/main";
import api from "./api_config";

function Home() {
  const [page, setPage] = useState("login");
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    if (auth_token) {
      const token = auth_token_type + " " + auth_token;
      setToken(token);
      api
          .get(`/users/`, {
            headers: {Authorization: token},
          })
          .then((response) => {
            console.log(response);
            setUser(response.data.result);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }, [token]);


  const chosePage = () => {
    if (page === "login") {
      return <Login setPage={setPage} />;
    }
    if (page === "forgot") {
      return <Forgot setPage={setPage} />;
    }
    if (page === "register") {
      return <Register setPage={setPage} />;
    }
  };

  const pages = () => {
    if (!token || !user) {
      return (
        <div className="min-h-screen bg-blue-400 flex justify-center items-center">
          <div className="py-12 px-12 bg-white rounded-2xl shadow-xl z-20">
            {chosePage()}
          </div>
        </div>
      );
    } else {
      return <Main user={user} />
    }
  };

  return <React.Fragment>{pages()}</React.Fragment>;
}

export default Home;
