import React, {Component} from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import { emptyBpmn } from './resources/template.jsx';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import Header from "./header";
import Footer from "./footer";
import Modeler from "./modeler";
import "bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css";
import "./styles/main.css";
import api from "../../api_config";
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from "react-toastify";
class Main extends Component {
    modeler = null;

    componentDidMount = () => {
            if (this.modeler === null) {
                this.modeler = new BpmnModeler({
                    container: '#bpmnview',
                    keyboard: {
                        bindTo: window
                    },
                    propertiesPanel: {
                        parent: '#propertiesview'
                    },
                    additionalModules: [
                        propertiesPanelModule,
                        propertiesProviderModule
                    ],
                    moddleExtensions: {
                        camunda: camundaModdleDescriptor
                    }
                });

                this.newBpmnDiagram();
            }
    }

    newBpmnDiagram = () => {
        this.openBpmnDiagram(emptyBpmn);
    }

    openBpmnDiagram = (xml) => {
        this.modeler.importXML(xml, (error) => {
            if (error) {
                return console.log('fail import xml');
            }

            var canvas = this.modeler.get('canvas');

            canvas.zoom('fit-viewport');
            this.setState({ diagramOpened: true });
        });
    }
    downloadDiagram = () => {
        this.modeler.saveXML({ format: true }, function(err, xml) {
            if (err) {
                return console.error("could not save BPMN 2.0 diagram", err);
            }
            const blob = new Blob([xml], { type: 'text/xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'diagram.bpmn';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
    };

    openDiagram = (event) => {
        const file = event.target.files[0]; // Obter o arquivo do evento
        const reader = new FileReader();

        reader.onload = (event) => {
            const xml = event.target.result;
            this.openBpmnDiagram(xml, (err) => {
                if (err) {
                    console.log("error rendering", err);
                } else {
                    console.log("rendered");
                }
            });
        };

        reader.readAsText(file);
    };


    saveDiagram = async () => {
        try {
            const xml = await new Promise((resolve, reject) => {
                this.modeler.saveXML({ format: true }, (err, xml) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(xml);
                    }
                });
            });

            const user_id = this.props.user.id;
            console.error(xml)
            const response = await api.post(`/bpmns/salve_xml/${user_id}`, { xml });

            console.log(response.data);
            toast.success('XML enviado com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar o XML:', error);
            toast.error('Erro ao enviar o XML. Consulte o console para mais detalhes.');
        }
    };

  render() {
    return (
      <React.Fragment>
        <Header
            modeler={this.modeler}
            saveDiagram={this.saveDiagram}
            downloadDiagram={this.downloadDiagram}
            openDiagram={this.openDiagram}
        />

        <div id="main-wrapper">
          <div id="canvas">
            <Modeler />
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
export default Main;
