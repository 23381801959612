import React, { Component}  from 'react';


class Modeler extends Component {
    render = () => {
        return(
            <div id="bpmncontainer">
                <div id="propertiesview" style={{ width: '25%', height: '98vh', float: 'right', maxHeight: '98vh', overflowX: 'auto', backgroundColor: "#D3D3D3" }}></div>
                <div id="bpmnview" style={{ width: '75%', height: '98vh', float: 'left' }}></div>
            </div>
        )
    }
}

export default Modeler;