import React, {Component} from 'react';
import {Col, Row, Dropdown} from 'react-bootstrap';

class Header extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <Row>
                <Col>
                    <div className="dropdown">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                File
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={this.props.downloadDiagram}>Download</Dropdown.Item>
                                <Dropdown.Item onClick={this.props.saveDiagram}>Save</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
                <Col>
                    <div>
                        <form>
                            <p>
                                <label htmlFor="file">Upload BPMN</label>
                                <input
                                    type="file"
                                    id="file"
                                    onChange={this.props.openDiagram}
                                    accept=".bpmn"
                                    multiple
                                />
                            </p>
                        </form>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default Header;